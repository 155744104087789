import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SxProps,
  TextField,
  Theme,
  Typography,
  darken,
  lighten,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Match, MatchBase } from "api/paddle/paddleStorageModels";
import CustomIcon from "components/CustomIcon";
import moment, { Moment } from "moment";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AvailablePlayer, AvailablePlayerBase, AvailableTeam } from "api/paddle/viewAllMatchesModels";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdvancedTable, AdvancedTableRecords } from "components/AdvancedTable";
import { IdAble } from "api/models";
import stringUtils from "utils/stringUtils";
import { useCopyToClipboard } from "usehooks-ts";
import objectUtils from "utils/objectUtil";
import { TypedJSON, jsonMember, jsonObject } from "typedjson";
import { typedJsonUtils } from "utils/typedJsonUtils";
import CloseIcon from "@mui/icons-material/Close";

const momentJsonOptions = typedJsonUtils.momentOptions("YYYY-MM-DD");

interface CreatePaddleMatchModalProps {
  isOpen: boolean;
  availableTeams: AvailableTeam[];
  availablePlayers: AvailablePlayer[];
  onClose: () => void;
}

interface MatchCsv {
  num: number;
  date: Moment;
  teamId: string;
  player1Id: string;
  player2Id: string;
  setOne: number;
  setTwo: number;
  setThree: number;
  points: number;
}

@jsonObject
class MatchDetailsTeam implements IdAble {
  @jsonMember(String)
  id: string = stringUtils.uuid();
  @jsonMember(String)
  name: string = "";
  @jsonMember(AvailablePlayerBase)
  playerOne: AvailablePlayer | null = null;
  @jsonMember(AvailablePlayerBase)
  playerTwo: AvailablePlayer | null = null;
  @jsonMember(Number)
  setOne: number = 0;
  @jsonMember(Number)
  setTwo: number = 0;
  @jsonMember(Number)
  setThree: number = 0;
  @jsonMember(Number)
  points: number = 0;
}

@jsonObject
class MatchDetails implements IdAble {
  @jsonMember(String)
  id: string = stringUtils.uuid();
  @jsonMember(moment, momentJsonOptions)
  matchDate: Moment | null = null;
  @jsonMember(MatchDetailsTeam)
  teamOne: MatchDetailsTeam = new MatchDetailsTeam();
  @jsonMember(MatchDetailsTeam)
  teamTwo: MatchDetailsTeam = new MatchDetailsTeam();
}

interface MatchSummary extends IdAble {
  id: string;
  matchDate: Moment;
  teamOneName: string;
  teamTwoName: string;
  setOne: string;
  setTwo: string;
  setThree: string;
  points: string;
  matchDetails: MatchDetails;
  match: Match;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const defaultMatch = (matchDate?: Moment | null): MatchDetails => {
  const matchDetails: MatchDetails = {
    id: stringUtils.uuid(),
    matchDate: matchDate ?? null,
    teamOne: {
      id: stringUtils.uuid(),
      name: "Team 1",
      playerOne: null,
      playerTwo: null,
      setOne: 0,
      setTwo: 0,
      setThree: 0,
      points: 2,
    },
    teamTwo: {
      id: stringUtils.uuid(),
      name: "Team 2",
      playerOne: null,
      playerTwo: null,
      setOne: 0,
      setTwo: 0,
      setThree: 0,
      points: 2,
    },
  };

  return matchDetails;
};

interface AvailablePlayersDeterminer {
  teamOneAvailablePlayers: AvailablePlayer[];
  teamTwoAvailablePlayers: AvailablePlayer[];
}

const determineAvailablePlayersForEachTeam = (
  availablePlayers: AvailablePlayer[],
  teamOneId: string | undefined,
  teamTwoId: string | undefined
): AvailablePlayersDeterminer => {
  if (teamOneId && !teamTwoId) {
    return {
      teamOneAvailablePlayers: availablePlayers.filter((p) => p.teamId === teamOneId),
      teamTwoAvailablePlayers: availablePlayers.filter((p) => p.teamId !== teamOneId),
    };
  }

  if (!teamOneId && teamTwoId) {
    return {
      teamOneAvailablePlayers: availablePlayers.filter((p) => p.teamId !== teamTwoId),
      teamTwoAvailablePlayers: availablePlayers.filter((p) => p.teamId === teamTwoId),
    };
  }

  if (teamOneId && teamTwoId) {
    return {
      teamOneAvailablePlayers: availablePlayers.filter((p) => p.teamId === teamOneId),
      teamTwoAvailablePlayers: availablePlayers.filter((p) => p.teamId === teamTwoId),
    };
  }

  return {
    teamOneAvailablePlayers: availablePlayers,
    teamTwoAvailablePlayers: availablePlayers,
  };
};

const CreatePaddleMatchModal = (props: CreatePaddleMatchModalProps) => {
  const { isOpen, availablePlayers, onClose } = props;
  const [availablePlayersForTeamOne, setAvailablePlayersForTeamOne] = useState<AvailablePlayer[]>(availablePlayers);
  const [availablePlayersForTeamTwo, setAvailablePlayersForTeamTwo] = useState<AvailablePlayer[]>(availablePlayers);
  const [matches, setMatches] = useState<Match[]>([]);
  const [matchDetails, setMatchDetails] = useState<MatchDetails>(defaultMatch);
  const [matchSummaries, setMatchSummaries] = useState<MatchSummary[]>([]);

  const [, copyToClipboard] = useCopyToClipboard();
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleAddMatch = () => {
    const clonedMatchDetails = objectUtils.deepCopyTypedObject(MatchDetails, matchDetails);
    const { matchDate, teamOne, teamTwo } = clonedMatchDetails;

    if (!matchDate || !teamOne.playerOne || !teamOne.playerTwo || !teamTwo.playerOne || !teamTwo.playerTwo) {
      alert("Invalid date. Please complete form");
      return;
    }

    const newMatch: Match = MatchBase.create({
      id: stringUtils.uuid(),
      matchDate: matchDate,
      teamOne: {
        teamId: teamOne.id,
        playerOneId: teamOne.playerOne.playerId,
        playerTwoId: teamOne.playerTwo.playerId,
        points: teamOne.points,
      },
      teamTwo: {
        teamId: teamTwo.id,
        playerOneId: teamTwo.playerOne.playerId,
        playerTwoId: teamTwo.playerTwo.playerId,
        points: teamTwo.points,
      },
      setOne: {
        teamOne: teamOne.setOne,
        teamTwo: teamTwo.setOne,
      },
      setTwo: {
        teamOne: teamOne.setTwo,
        teamTwo: teamTwo.setTwo,
      },
      setThree: {
        teamOne: teamOne.setThree,
        teamTwo: teamTwo.setThree,
      },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    });

    const newMatchSummary: MatchSummary = {
      id: (matchSummaries.length + 1).toString(),
      matchDetails: clonedMatchDetails,
      match: newMatch,
      matchDate: matchDate,
      teamOneName: `(${teamOne.name}) ${teamOne.playerOne?.displayName}/${teamOne.playerTwo?.displayName}`,
      teamTwoName: `(${teamTwo.name}) ${teamTwo.playerOne?.displayName}/${teamTwo.playerTwo?.displayName}`,
      setOne: `${teamOne.setOne}/${teamTwo.setOne}`,
      setTwo: `${teamOne.setTwo}/${teamTwo.setTwo}`,
      setThree: `${teamOne.setThree}/${teamTwo.setThree}`,
      points: `${teamOne.points}/${teamTwo.points}`,
    };

    setMatches([...matches, newMatch]);
    setMatchSummaries([...matchSummaries, newMatchSummary]);
    setMatchDetails(defaultMatch(matchDate));
    setAvailablePlayersForTeamOne(availablePlayers);
    setAvailablePlayersForTeamTwo(availablePlayers);
  };

  const getCsv = (): string => {
    const matchesForCsv = matchSummaries.flatMap((newMatchSummary, i) => {
      const { match: newMatch } = newMatchSummary;
      const matchNum = i + 1;

      const matchForTeamOne: MatchCsv = {
        num: matchNum,
        date: newMatch.matchDate,
        teamId: newMatch.teamOne.teamId,
        player1Id: newMatch.teamOne.playerOneId,
        player2Id: newMatch.teamOne.playerTwoId,
        setOne: newMatch.setOne.teamOne,
        setTwo: newMatch.setTwo.teamOne,
        setThree: newMatch.setThree?.teamOne ?? 0,
        points: newMatch.teamOne.points,
      };

      const matchForTeamTwo: MatchCsv = {
        num: matchNum,
        date: newMatch.matchDate,
        teamId: newMatch.teamTwo.teamId,
        player1Id: newMatch.teamTwo.playerOneId,
        player2Id: newMatch.teamTwo.playerTwoId,
        setOne: newMatch.setOne.teamTwo,
        setTwo: newMatch.setTwo.teamTwo,
        setThree: newMatch.setThree?.teamTwo ?? 0,
        points: newMatch.teamTwo.points,
      };

      return [matchForTeamOne, matchForTeamTwo];
    });

    var body = "Match,Match Date,Team,Player 1,Player 2,Set 1,Set 2,Set 3,Points";
    for (var match of matchesForCsv) {
      const { num, date, teamId, player1Id, player2Id, setOne, setTwo, setThree, points } = match;
      const formattedDate = moment(date).format("MM/DD/YYYY");
      const newLine = `\n${num},${formattedDate},${teamId},${player1Id},${player2Id},${setOne},${setTwo},${setThree},${points}`;
      body += newLine;
    }

    return body;
  };

  const getJson = (): string => {
    const serializer = new TypedJSON(MatchBase, {
      indent: 2,
    });
    const matchBases = matches.map((match) => MatchBase.create(match));
    const json = serializer.stringifyAsArray(matchBases);
    return json;
  };

  const handleEmailSend = () => {
    var to = "Patrick Truskowski<ptruskowski@gmail.com>";
    var subject = "New Matches";
    var body = getCsv();

    var createGmailLink = (to: string, subject: string, body: string) => {
      return (
        "https://mail.google.com/mail/?view=cm" +
        "&to=" +
        encodeURIComponent(to) +
        "&su=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(body)
      );
    };
    var gmailLink = createGmailLink(to, subject, body);

    if (gmailLink.length > 2048) {
      gmailLink = createGmailLink(
        to,
        subject,
        "Message too long. Please go back, press the 'Copy' button, and paste the match data in your email."
      );
    }

    window.open(gmailLink, "_blank");
  };

  const sortByTeamThenName = (a: AvailablePlayer, b: AvailablePlayer) => {
    if (a.teamId !== b.teamId) {
      return a.teamDisplayName.toLocaleLowerCase().localeCompare(b.teamDisplayName.toLocaleLowerCase());
    }

    if (a.displayName.toLocaleLowerCase().includes("n/a")) {
      return 1;
    }

    if (b.displayName.toLocaleLowerCase().includes("n/a")) {
      return -1;
    }

    return a.displayName.toLocaleLowerCase().localeCompare(b.displayName.toLocaleLowerCase());
  };

  const dialogActionSx: SxProps<Theme> | undefined = isMdScreen
    ? { position: "absolute", bottom: "1em", right: "1em", left: "1em", justifyContent: "center" }
    : { pb: "1em", pr: "1em" };

  const { teamOne, teamTwo, matchDate } = matchDetails;

  const matchSummariesTable: AdvancedTableRecords<MatchSummary> = {
    fields: [
      {
        id: "matchDate",
        label: "Date",
        custom: (val) => {
          return moment(val.matchDate).format("M/D/YYYY");
        },
        readOnly: true,
      },
      { id: "teamOneName", label: "Team One", readOnly: true },
      { id: "teamTwoName", label: "Team Two", readOnly: true },
      { id: "setOne", label: "S1", readOnly: true },
      { id: "setTwo", label: "S2", readOnly: true },
      { id: "setThree", label: "S3", readOnly: true },
      { id: "points", label: "Points", readOnly: true },
    ],
    data: matchSummaries,
    actions: [
      {
        icon: "delete",
        onClick: (item: MatchSummary) => {
          const newMatchSummaries = matchSummaries.filter((matchSummary) => matchSummary.id !== item.id);
          setMatchSummaries(newMatchSummaries);
        },
      },
    ],
  };

  const updatePlayers = (
    teamKey: keyof Pick<MatchDetails, "teamOne" | "teamTwo">,
    playerKey: keyof Pick<MatchDetailsTeam, "playerOne" | "playerTwo">,
    newValue: AvailablePlayer | null
  ) => {
    const newState = objectUtils.deepCopyTypedObject(MatchDetails, matchDetails);
    newState[teamKey][playerKey] = newValue;

    newState[teamKey].name = teamKey === "teamOne" ? "Team 1" : "Team 2";

    if (newState[teamKey].playerOne) {
      newState[teamKey].name = newState[teamKey].playerOne!.teamDisplayName;
    }

    if (newState[teamKey].playerTwo) {
      newState[teamKey].name = newState[teamKey].playerTwo!.teamDisplayName;
    }

    const teamOneId = newState.teamOne.playerOne
      ? newState.teamOne.playerOne.teamId
      : newState.teamOne.playerTwo?.teamId;

    newState.teamOne.id = teamOneId ?? stringUtils.uuid();

    const teamTwoId = newState.teamTwo.playerOne
      ? newState.teamTwo.playerOne.teamId
      : newState.teamTwo.playerTwo?.teamId;

    newState.teamTwo.id = teamTwoId ?? stringUtils.uuid();

    const { teamOneAvailablePlayers, teamTwoAvailablePlayers } = determineAvailablePlayersForEachTeam(
      availablePlayers,
      teamOneId,
      teamTwoId
    );

    setAvailablePlayersForTeamOne(teamOneAvailablePlayers);
    setAvailablePlayersForTeamTwo(teamTwoAvailablePlayers);

    setMatchDetails(newState);
  };

  const updateSet = (
    teamKey: keyof Pick<MatchDetails, "teamOne" | "teamTwo">,
    setKey: keyof Pick<MatchDetailsTeam, "setOne" | "setTwo" | "setThree">,
    newValue: string
  ) => {
    const newValueNum = stringUtils.isNumber(newValue) ? parseInt(newValue) : 0;

    const newState = objectUtils.deepCopyTypedObject(MatchDetails, matchDetails);
    newState[teamKey][setKey] = newValueNum;

    // TODO Determine the logic for determining the points
    const { setOne: oneOne, setTwo: oneTwo, setThree: oneThree } = newState.teamOne;
    const { setOne: twoOne, setTwo: twoTwo, setThree: twoThree } = newState.teamTwo;

    const hasEnoughDataToEvaluate = (oneOne >= 6 || twoOne >= 6) && (oneTwo >= 6 || twoTwo >= 6);
    if (!hasEnoughDataToEvaluate) {
      newState.teamOne.points = 2;
      newState.teamTwo.points = 2;
      setMatchDetails(newState);
      return;
    }

    if (oneThree <= 0 && twoThree <= 0) {
      // TODO who has more points takes points=3 && points=0
      if (oneOne > twoOne) {
        newState.teamOne.points = 3;
        newState.teamTwo.points = 0;
      }

      if (oneOne < twoOne) {
        newState.teamOne.points = 0;
        newState.teamTwo.points = 3;
      }
    } else {
      // TODO points=3, && points=1
      if (oneThree > twoThree) {
        newState.teamOne.points = 3;
        newState.teamTwo.points = 1;
      }

      if (oneThree < twoThree) {
        newState.teamOne.points = 1;
        newState.teamTwo.points = 3;
      }
    }

    setMatchDetails(newState);
  };

  const onSetFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth fullScreen={isMdScreen}>
      <DialogTitle>{`Add Matches (${matches.length})`}</DialogTitle>
      <CustomIcon
        aria-label="close"
        icon="close"
        onClick={onClose}
        style={{
          position: "absolute",
          right: "1.5em",
          top: "0.75em",
        }}
      />
      <DialogContent>
        <Grid container spacing={2} sx={{ px: "2em", pb: "2em", pt: "1em" }}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {teamOne.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={"flex"} justifyContent={"center"} sx={{ width: "100%" }}>
                  <Autocomplete
                    id="teamOne-playerOne-autocomplete"
                    sx={{ width: "100%", mb: "1em", mr: "0.5em" }}
                    options={availablePlayersForTeamOne.sort(sortByTeamThenName)}
                    groupBy={(option) => option.teamDisplayName}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(a, b) => a.playerId === b.playerId}
                    value={teamOne.playerOne}
                    onChange={(_, newValue) => updatePlayers("teamOne", "playerOne", newValue)}
                    renderInput={(params) => <TextField {...params} label="Player One" />}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    disableClearable={false}
                  />
                  <Autocomplete
                    id="teamOne-playerTwo-autocomplete"
                    sx={{ width: "100%", mb: "1em" }}
                    options={availablePlayersForTeamOne.sort(sortByTeamThenName)}
                    groupBy={(option) => option.teamDisplayName}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(a, b) => a.playerId === b.playerId}
                    value={teamOne.playerTwo}
                    onChange={(_, newValue) => updatePlayers("teamOne", "playerTwo", newValue)}
                    renderInput={(params) => <TextField {...params} label="Player Two" />}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    disableClearable={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 1"
                      type="number"
                      value={teamOne.setOne}
                      onChange={(newValue) => updateSet("teamOne", "setOne", newValue.target.value)}
                      onFocus={onSetFocus}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 2"
                      type="number"
                      value={teamOne.setTwo}
                      onChange={(newValue) => updateSet("teamOne", "setTwo", newValue.target.value)}
                      onFocus={onSetFocus}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 3"
                      type="number"
                      value={teamOne.setThree}
                      onChange={(newValue) => updateSet("teamOne", "setThree", newValue.target.value)}
                      onFocus={onSetFocus}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Points"
                      type="number"
                      value={teamOne.points}
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
              {teamTwo.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={"flex"} justifyContent={"center"} sx={{ width: "100%" }}>
                  <Autocomplete
                    id="teamTwo-playerOne-autocomplete"
                    sx={{ width: "100%", mb: "1em", mr: "0.5em" }}
                    options={availablePlayersForTeamTwo.sort(sortByTeamThenName)}
                    groupBy={(option) => option.teamDisplayName}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(a, b) => a.playerId === b.playerId}
                    value={teamTwo.playerOne}
                    onChange={(_, newValue) => updatePlayers("teamTwo", "playerOne", newValue)}
                    renderInput={(params) => <TextField {...params} label="Player One" />}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    disableClearable={false}
                  />
                  <Autocomplete
                    id="teamTwo-playerTwo-autocomplete"
                    sx={{ width: "100%", mb: "1em" }}
                    options={availablePlayersForTeamTwo.sort(sortByTeamThenName)}
                    groupBy={(option) => option.teamDisplayName}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(a, b) => a.playerId === b.playerId}
                    value={teamTwo.playerTwo}
                    onChange={(_, newValue) => updatePlayers("teamTwo", "playerTwo", newValue)}
                    renderInput={(params) => <TextField {...params} label="Player Two" />}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    disableClearable={false}
                  />
                </Box>
              </Grid>
              {/* <Divider /> */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 1"
                      type="number"
                      value={teamTwo.setOne}
                      onChange={(newValue) => updateSet("teamTwo", "setOne", newValue.target.value)}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 2"
                      type="number"
                      value={teamTwo.setTwo}
                      onChange={(newValue) => updateSet("teamTwo", "setTwo", newValue.target.value)}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Set 3"
                      type="number"
                      value={teamTwo.setThree}
                      onChange={(newValue) => updateSet("teamTwo", "setThree", newValue.target.value)}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Points"
                      type="number"
                      value={teamTwo.points}
                      autoComplete="off"
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center" sx={{ mt: "1em" }}>
              <DatePicker
                label="Match Date"
                sx={{ w: "100%", mb: "1em" }}
                value={matchDate}
                onChange={(newDate) => {
                  const newState = objectUtils.deepCopyTypedObject(MatchDetails, matchDetails);
                  newState.matchDate = newDate;
                  setMatchDetails(newState);
                }}
              />
              <Box>
                <Button
                  onClick={handleAddMatch}
                  endIcon={<AddIcon />}
                  color="primary"
                  variant="contained"
                  sx={{ mb: "1em", ml: "1em" }}
                >
                  {"Add Match"}
                </Button>
              </Box>
            </Box>
          </Grid>
          {matchSummaries.length ? (
            <Grid item>
              <AdvancedTable
                title={""}
                table={matchSummariesTable}
                orderByKey={"id"}
                onChange={() => {}}
                onDelete={() => {}}
                onChangeAll={() => {}}
                minWidthPx={0}
                hidePagination
                hideHeader
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={dialogActionSx}>
        <Button onClick={() => handleEmailSend()} endIcon={<SendIcon />} color="primary" variant="contained">
          {"Send w/ Gmail"}
        </Button>
        <Button onClick={() => copyToClipboard(getCsv())} endIcon={<ContentCopyIcon />} variant="contained">
          {"Copy CSV"}
        </Button>
        <Button onClick={() => copyToClipboard(getJson())} endIcon={<ContentCopyIcon />} variant="contained">
          {"Copy JSON"}
        </Button>
        <Button onClick={onClose} variant="outlined" endIcon={<CloseIcon />}>
          {"Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePaddleMatchModal;
