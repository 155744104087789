import { MatchBase, PaddleClub, PaddleClubBase } from "api/paddle/paddleStorageModels";
import { TypedJSON } from "typedjson";
// import stringUtils from "./stringUtils";
import moment from "moment";

const paddleClubDataKey = "paddleClubData";

/**
 * Missing Players/Emails:
 * Charles Pall <chas.pall@gmail.com>,
 * Gribble, Pete <gpgribble@aol.com>,
 * Gribble, Pete <ougrib@aol.com>,
 * Jay Muldoon-Stetson <jaymuldstets@gmail.com>,
 * Jerry Joyce <jeremiah_joyce@msn.com>,
 * Kevin Malloy <kmalloy@fordellp.com>,
 * Scott Roberts <tromper727@yahoo.com>,
 * Skalsky, George <gskalsky@yahoo.com>,
 * Dan Fahey <danfahey1@gmail.com>,
 * Greg and Amy Liss <greg.liss101@gmail.com>,
 * Nick LoMaglio <nicholas.lomaglio@gmail.com>,
 * tk1102ww@hotmail.com <tk1102ww@hotmail.com>,
 * martymaloney04@gmail.com <martymaloney04@gmail.com>
 */
const defaultPaddleClubBase = PaddleClubBase.create({
  teams: [
    {
      id: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      name: "Bohr",
      captainPlayerId: "",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      name: "Keeley",
      captainPlayerId: "",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      name: "Vinny",
      captainPlayerId: "",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      name: "Wilmes",
      captainPlayerId: "",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
  ],
  players: [
    {
      id: "792cd09b-f61d-4c6d-8e44-4562213bbc02",
      firstName: "Bill",
      lastName: "Bohr",
      nickname: "Bill",
      fullName: "Bohr, Bill",
      email: "wrb1979@gmail.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
      firstName: "Chris",
      lastName: "Berghoff",
      nickname: "Chris",
      fullName: "Berghoff, Chris",
      email: "chris@berghofflaw.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "aff13e2d-edf8-4294-9172-05a3766838a3",
      firstName: "Dan",
      lastName: "Pfandler",
      nickname: "Dan",
      fullName: "Pfandler, Dan",
      email: "danpfandler@gmail.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
      firstName: "Dan",
      lastName: "Urbut",
      nickname: "Dan",
      fullName: "Urbut, Dan",
      email: "djurbut@yahoo.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "953b3d82-a9ac-47c4-9256-2857d68297ad",
      firstName: "Dan",
      lastName: "Waddell",
      nickname: "Dan",
      fullName: "Waddell, Dan",
      email: "waddelldanielj@gmail.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "3096d7a0-d877-429c-bb35-c0119e75b4b9",
      firstName: "Joe",
      lastName: "Flamm",
      nickname: "Joe",
      fullName: "Flamm, Joe",
      email: "joseph.flamm@gmail.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
      firstName: "John",
      lastName: "Cummings",
      nickname: "John",
      fullName: "Cummings, John",
      email: "johnbcummings@gmail.com",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
      firstName: "John",
      lastName: "Wilson",
      nickname: "John",
      fullName: "Wilson, John",
      email: "John Wilson <jow9030@gmail.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
      firstName: "Mike",
      lastName: "Stanton",
      nickname: "Mike",
      fullName: "Stanton, Mike",
      email: "Mike Stanton <mpstanton57@gmail.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "c3bf2b72-c52a-4bd4-9e95-bdae4f228fc1",
      firstName: "Ryan",
      lastName: "Moody",
      nickname: "Ryan",
      fullName: "Moody, Ryan",
      email: "Ryan Moody <rmoody409@yahoo.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "135a8f4b-528a-4ab8-be47-17efe09aee28",
      firstName: "Tito",
      lastName: "Ilarraza",
      nickname: "Tito",
      fullName: "Ilarraza, Tito",
      email: "Ilarraza, Tito <titocubs@gmail.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
      firstName: "Tom",
      lastName: "Ruggieri",
      nickname: "Tom",
      fullName: "Ruggieri, Tom",
      email: "Tom Ruggieri <tomruggieri@yahoo.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "fee2925f-98f7-4ead-9580-ec61d266c370",
      firstName: "Tony",
      lastName: "Balta",
      nickname: "Tony",
      fullName: "Balta, Tony",
      email: "Balta, Anthony <tanai@comcast.net>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "50f96f0a-647b-43af-8b72-b5e52f23698c",
      firstName: "Brian",
      lastName: "Keeley",
      nickname: "Brian",
      fullName: "Keeley, Brian",
      email: "brian keeley <keeleybrian@yahoo.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
      firstName: "Chris",
      lastName: "Kent",
      nickname: "Chris",
      fullName: "Kent, Chris",
      email: "Christopher Kent <dakar92@sbcglobal.net>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
      firstName: "Jeff",
      lastName: "Holland",
      nickname: "Jeff",
      fullName: "Holland, Jeff",
      email: "Holland, Jeff <jholland@hollandhickslaw.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "af43c2ab-e945-4a05-8976-965a0be20690",
      firstName: "Joe",
      lastName: "LoMaglio",
      nickname: "Joe",
      fullName: "LoMaglio, Joe",
      email: "Joe LoMaglio <joseph.lomaglio@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "e9303047-a99a-472c-8921-b28f2cc48b2e",
      firstName: "Jose",
      lastName: "Colon",
      nickname: "Jose",
      fullName: "Colon, Jose",
      email: "Colon, Jose <jjcolon@sbcglobal.net>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ff0dcf72-910e-422e-ac22-584d671b3980",
      firstName: "Kevin",
      lastName: "Fitzpatrick",
      nickname: "Kevin",
      fullName: "Fitzpatrick, Kevin",
      email: "Fitzpatrick, Kevin <fitzs3@yahoo.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "0f034fc9-ff88-484d-953c-31473bd83c69",
      firstName: "Mark",
      lastName: "Singler",
      nickname: "Mark",
      fullName: "Singler, Mark",
      email: "Mark Singler <marksingler@yahoo.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
      firstName: "Matt",
      lastName: "Halleran",
      nickname: "Matt",
      fullName: "Halleran, Matt",
      email: "Matt Halleran <matt.halleran@spiral-helix.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "d9b904be-fb47-4271-8603-43df0a3e36f9",
      firstName: "Pat",
      lastName: "Flood",
      nickname: "Pat",
      fullName: "Flood, Pat",
      email: "Patrick Flood <pflood@q20lab.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
      firstName: "Quinn",
      lastName: "Reilly",
      nickname: "Quinn",
      fullName: "Reilly, Quinn",
      email: "Quinn Reilly <qreilly2@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
      firstName: "Tom",
      lastName: "Stasaitis",
      nickname: "Tom",
      fullName: "Stasaitis, Tom",
      email: "Stasaitis, Tom <thomas.stasaitis@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
      firstName: "Brendan",
      lastName: "Sheils",
      nickname: "Brendan",
      fullName: "Sheils, Brendan",
      email: "Brendan Sheils <bsheils09@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "523077cc-506d-46b5-b435-f32dca04a838",
      firstName: "Eddie",
      lastName: "Garza",
      nickname: "Eddie",
      fullName: "Garza, Eddie",
      email: "Eduardo Garza <eduardogarza5@hotmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "56aa4026-9d1e-4367-8eee-d564d2777e99",
      firstName: "Eddie",
      lastName: "McNicholas",
      nickname: "Eddie",
      fullName: "McNicholas, Eddie",
      email: "Eddie McNicholas <eddie.mcnicholas13@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "eda5b116-16f3-4ca8-b75e-020965605004",
      firstName: "Jeff",
      lastName: "Farley",
      nickname: "Jeff",
      fullName: "Farley, Jeff",
      email: "Jeff Farley <jtfarley@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "80f30866-fe10-4d6a-9f23-769f15d98e16",
      firstName: "Mike",
      lastName: "Alving",
      nickname: "Mike",
      fullName: "Alving, Mike",
      email: "Michael Alving <michael.alving@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
      firstName: "Mike",
      lastName: "Geary",
      nickname: "Mike",
      fullName: "Geary, Mike",
      email: "Mike and Nancy Geary <mpgeary@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5d843b32-69fd-43d6-877e-57bd6acccb46",
      firstName: "Pat",
      lastName: "Stanton",
      nickname: "Pat",
      fullName: "Stanton, Pat",
      email: "Stanton, Pat <patricktstanton@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
      firstName: "Pat",
      lastName: "Truskowski",
      nickname: "Patrick",
      fullName: "Truskowski, Patrick",
      email: "Patrick Truskowski <ptruskowski@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
      firstName: "Peter",
      lastName: "Hutcheon",
      nickname: "Peter",
      fullName: "Hutcheon, Peter",
      email: "Peter Hutcheon <peterghutcheon@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "a5cc942f-2449-4e71-8c61-3699bc59707a",
      firstName: "Tom",
      lastName: "O'Connor",
      nickname: "Tom",
      fullName: "O'Connor, Tom",
      email: "Tom O'Connor <toconnor@bhhschicago.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "b5a39031-e4f2-4390-844d-c5aab4d22575",
      firstName: "Vinayak",
      lastName: "Thiagarajan",
      nickname: "Vinny",
      fullName: "Thiagarajan, Vinayak",
      email: "Vinny Thiagarajan <vinayak.thiagarajan@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
      firstName: "Brad",
      lastName: "McNicholas",
      nickname: "Brad",
      fullName: "McNicholas, Brad",
      email: "Brad McNicholas <bradleywmcnicholas@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "4259ac3a-46d6-4f35-adfb-0c2fcf684101",
      firstName: "Brian",
      lastName: "Cleary",
      nickname: "Brian",
      fullName: "Cleary, Brian",
      email: "Brian Cleary <bcleary155@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "24e245e4-e9c9-4ce6-aacc-d642061a2854",
      firstName: "Brian",
      lastName: "Dusak",
      nickname: "Brian",
      fullName: "Dusak, Brian",
      email: "Brian Dusak <bdusak@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
      firstName: "Brian",
      lastName: "Livermore",
      nickname: "Brian",
      fullName: "Livermore, Brian",
      email: "Livermore, Brian <brilivmor@yahoo.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
      firstName: "Joe",
      lastName: "Herber",
      nickname: "Joe",
      fullName: "Herber, Joe",
      email: "Joe Herber <joeherber@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5d39c67c-9736-42f5-a302-b10c415095c9",
      firstName: "Joe",
      lastName: "Kratzer",
      nickname: "Joe",
      fullName: "Kratzer, Joe",
      email: "Joe Kratzer <kratzerjoe@hotmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
      firstName: "John",
      lastName: "Doran",
      nickname: "John",
      fullName: "Doran, John",
      email: "",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
      firstName: "Pat",
      lastName: "Hogan",
      nickname: "Pat",
      fullName: "Hogan, Pat",
      email: "Patrick Hogan <patrick.hogan.20@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "c41891d5-c108-41ea-a13b-83750e0e377b",
      firstName: "Shea",
      lastName: "Roche",
      nickname: "Shea",
      fullName: "Roche, Shea",
      email: "Shea Roche <sproche11@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1138f590-58ea-4747-bffc-70f897c06e0e",
      firstName: "Tom",
      lastName: "McDonell",
      nickname: "Tom",
      fullName: "McDonell, Tom",
      email: "Tom McDonell <tmcdonell1@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "58b67beb-e622-46d8-906f-609d3cb69cfe",
      firstName: "Tony",
      lastName: "Morales",
      nickname: "Tony",
      fullName: "Morales, Tony",
      email: "Tony Morales <antonio.c.morales@outlook.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "fbeee4f3-ca39-4cf5-bf9d-10b53606c78c",
      firstName: "Bill",
      lastName: "Klingler",
      nickname: "Bill",
      fullName: "Klingler, Bill",
      email: "Bill Klingler <billklingler44@gmail.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
      firstName: "Dan",
      lastName: "Callaghan",
      nickname: "Dan",
      fullName: "Callaghan, Dan",
      email: "Dan Callaghan <gca1@comcast.net>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8ffb6e94-32a8-4aac-9293-f05ddd521e13",
      firstName: "Jake",
      lastName: "Geary",
      nickname: "Jake",
      fullName: "Geary, Jake",
      email: "Jake Geary <jpgeary95@yahoo.com>",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8e1f4e39-1383-490c-b1b5-b3f99525aeb8",
      firstName: "Brian",
      lastName: "Wilson",
      nickname: "Brian",
      fullName: "Wilson, Brian",
      email: "Wilson, Brian <brian.wilson@pendoadvisors.com>",
      secondaryEmail: "Wilson, Brian <bwilsoniu@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "0b06b26b-c804-4d61-bc8b-802ebbb71ed8",
      firstName: "Chris",
      lastName: "Sullivan",
      nickname: "Chris",
      fullName: "Sullivan, Chris",
      email: "Sullivan, Chris <csullivanmd@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "6fafaa6d-27e0-4c05-ad54-c43e07f41903",
      firstName: "Gavin",
      lastName: "McAuliffe",
      nickname: "Gavin",
      fullName: "McAuliffe, Gavin",
      email: "Gavin McAuliffe <gpmcauliffe@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "6f590988-4ea3-4f2f-a741-08dd4e148662",
      firstName: "Jerry",
      lastName: "Scholl",
      nickname: "Jerry",
      fullName: "Scholl, Jerry",
      email: "Jerry Scholl <jerry1scholl@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "9ceeffc4-81db-457c-bd26-536599d51124",
      firstName: "Tom",
      lastName: "McCormick",
      nickname: "Tom",
      fullName: "McCormick, Tom",
      email: "Tom McCormick <tmccormick0614@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
      firstName: "Will",
      lastName: "Waddell",
      nickname: "Will",
      fullName: "Waddell, Will",
      email: "Will Waddell <waddellwilliamc@gmail.com>",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1cd72d27-6b4d-4399-a383-123b2fd95d75",
      firstName: "Carm",
      lastName: "Bottari",
      nickname: "Carm",
      fullName: "Bottari, Carm",
      email: "CarmTina Bottari <bottari13@yahoo.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "0ba76f83-4020-4706-9162-9d23c89842d4",
      firstName: "George",
      lastName: "Carson",
      nickname: "George",
      fullName: "Carson, George",
      email: "George and Noelle Carson <georgecarson2010@gmail.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8cf32cd8-635f-4929-9652-ef87521eced2",
      firstName: "Mike",
      lastName: "Cleary",
      nickname: "Mike",
      fullName: "Cleary, Mike",
      email: "",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "449e317d-11dc-4b5f-909b-04ceda005039",
      firstName: "Neil",
      lastName: "Byers",
      nickname: "Neil",
      fullName: "Byers, Neil",
      email: "Neil Byers <nbyers83@yahoo.com>",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
      firstName: "Chris",
      lastName: "Wilmes",
      nickname: "Chris",
      fullName: "Wilmes, Chris",
      email: "Christopher Wilmes <wilmes_nd@yahoo.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "b70f2b9e-034c-42b3-b389-7e49a3294f50",
      firstName: "Dennis",
      lastName: "Ryan",
      nickname: "Dennis",
      fullName: "Ryan, Dennis",
      email: "Dennis and Jean Ryan <dnjryan98@yahoo.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "94acf0fe-9e87-4cdb-818e-b8a196153b4c",
      firstName: "Patrick",
      lastName: "Singler",
      nickname: "Patrick",
      fullName: "Singler, Patrick",
      email: "Patrick Singler <patrickksingler@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f2e220d0-091c-4dd6-bbbf-764b2a8fb3df",
      firstName: "Tim",
      lastName: "Bonen",
      nickname: "Tim",
      fullName: "Bonen, Tim",
      email: "Tim Bonen <tim.bonen@gmail.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "eda40a83-8fee-41cc-8165-741ba0919bc0",
      firstName: "Tim",
      lastName: "Kelly",
      nickname: "Tim",
      fullName: "Kelly, Tim",
      email: "Tim Kelly <tjktrade@yahoo.com>",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "0b7e4870-cdb9-4058-aa69-8c2b4a741f79",
      firstName: "Tim",
      lastName: "Winters",
      nickname: "Tim",
      fullName: "Winters, Tim",
      email: "",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "57b4c3c1-27d7-49f0-bd9b-a0f90da71bd2",
      firstName: "Marty",
      lastName: "Maloney",
      nickname: "Marty",
      fullName: "Marty Maloney",
      email: "martymaloney04@gmail.com",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "cd8faa9c-ad15-418e-9485-d02133dc8410",
      firstName: "n/a",
      lastName: "n/a",
      nickname: "n/a",
      fullName: "n/a (Wilmes)",
      email: "",
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "e67a8a71-563a-4a98-ada2-fe324989f774",
      firstName: "n/a",
      lastName: "n/a",
      nickname: "n/a",
      fullName: "n/a (Vinny)",
      email: "",
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "4f152226-6cf3-4474-8043-902ba4fda319",
      firstName: "n/a",
      lastName: "n/a",
      nickname: "n/a",
      fullName: "n/a (Keeley)",
      email: "",
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8a988843-6dbd-4064-ac05-d0b628546b86",
      firstName: "n/a",
      lastName: "n/a",
      nickname: "n/a",
      fullName: "n/a (Bohr)",
      email: "",
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
  ],
  matches: [
    {
      id: "31e44022-c19f-4d13-b449-de12e42a7fb2",
      matchDate: moment("2024-01-08", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "953b3d82-a9ac-47c4-9256-2857d68297ad",
        playerTwoId: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0f034fc9-ff88-484d-953c-31473bd83c69",
        playerTwoId: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
        points: 3,
      },
      setOne: { teamOne: 0, teamTwo: 6 },
      setTwo: { teamOne: 2, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f680f945-4cb7-4f3d-a9b8-dc4629e19e7e",
      matchDate: moment("2024-01-08", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
        playerTwoId: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "af43c2ab-e945-4a05-8976-965a0be20690",
        playerTwoId: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 4 },
      setTwo: { teamOne: 6, teamTwo: 4 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "8166e012-43ca-4346-adc8-ade2cc3b8f3f",
      matchDate: moment("2024-01-08", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "5d39c67c-9736-42f5-a302-b10c415095c9",
        playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
        points: 1,
      },
      teamTwo: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "eda5b116-16f3-4ca8-b75e-020965605004",
        playerTwoId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        points: 3,
      },
      setOne: { teamOne: 7, teamTwo: 5 },
      setTwo: { teamOne: 2, teamTwo: 6 },
      setThree: { teamOne: 2, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "7b40b9c8-6bbb-42fd-aa52-ce6addf1145f",
      matchDate: moment("2024-01-08", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
        playerTwoId: "24e245e4-e9c9-4ce6-aacc-d642061a2854",
        points: 0,
      },
      teamTwo: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "523077cc-506d-46b5-b435-f32dca04a838",
        playerTwoId: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 2, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ba28674e-44c7-418e-9bf9-20939ab06062",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        playerTwoId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "ff0dcf72-910e-422e-ac22-584d671b3980",
        playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
        points: 3,
      },
      setOne: { teamOne: 4, teamTwo: 6 },
      setTwo: { teamOne: 0, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5c22ee09-fedf-4534-bf25-579232b2ec25",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "792cd09b-f61d-4c6d-8e44-4562213bbc02",
        playerTwoId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        points: 2,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
        playerTwoId: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
        points: 2,
      },
      setOne: { teamOne: 6, teamTwo: 4 },
      setTwo: { teamOne: 3, teamTwo: 6 },
      setThree: { teamOne: 4, teamTwo: 4 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "49415d08-c168-4d26-be75-8a8daf2258ff",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "3096d7a0-d877-429c-bb35-c0119e75b4b9",
        playerTwoId: "aff13e2d-edf8-4294-9172-05a3766838a3",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
        playerTwoId: "d9b904be-fb47-4271-8603-43df0a3e36f9",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 4 },
      setTwo: { teamOne: 6, teamTwo: 4 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "e5a6e21f-3240-4ab5-8fd7-2462cf8cbc52",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "4259ac3a-46d6-4f35-adfb-0c2fcf684101",
        playerTwoId: "58b67beb-e622-46d8-906f-609d3cb69cfe",
        points: 3,
      },
      teamTwo: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        playerTwoId: "80f30866-fe10-4d6a-9f23-769f15d98e16",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 6, teamTwo: 2 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "6c12583f-9ee6-4005-b75e-a1b985b69213",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
        playerTwoId: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
        points: 0,
      },
      teamTwo: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        playerTwoId: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
        points: 3,
      },
      setOne: { teamOne: 5, teamTwo: 7 },
      setTwo: { teamOne: 4, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "9e92192f-b6f4-4fe7-a79f-7a0c3805a96d",
      matchDate: moment("2024-01-10", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "1138f590-58ea-4747-bffc-70f897c06e0e",
        playerTwoId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        points: 3,
      },
      teamTwo: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
        playerTwoId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
        points: 1,
      },
      setOne: { teamOne: 5, teamTwo: 7 },
      setTwo: { teamOne: 6, teamTwo: 0 },
      setThree: { teamOne: 6, teamTwo: 2 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "711023fc-f55d-43a9-ac27-360a173be01e",
      matchDate: moment("2024-01-15", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "b5a39031-e4f2-4390-844d-c5aab4d22575",
        playerTwoId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
        playerTwoId: "50f96f0a-647b-43af-8b72-b5e52f23698c",
        points: 0,
      },
      setOne: { teamOne: 9, teamTwo: 8 },
      setTwo: { teamOne: 6, teamTwo: 0 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "6a42e8a2-ffed-4d82-8c48-a8387a5ca061",
      matchDate: moment("2024-01-15", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0f034fc9-ff88-484d-953c-31473bd83c69",
        playerTwoId: "af43c2ab-e945-4a05-8976-965a0be20690",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 2 },
      setTwo: { teamOne: 7, teamTwo: 5 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "daf9b6f2-d2d4-47b3-b29f-82a7097499b8",
      matchDate: moment("2024-01-15", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "1138f590-58ea-4747-bffc-70f897c06e0e",
        playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "fee2925f-98f7-4ead-9580-ec61d266c370",
        playerTwoId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        points: 1,
      },
      setOne: { teamOne: 5, teamTwo: 7 },
      setTwo: { teamOne: 6, teamTwo: 2 },
      setThree: { teamOne: 6, teamTwo: 3 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f2b1fd86-beca-4c85-9cb3-6255de4db0d7",
      matchDate: moment("2024-01-15", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
        playerTwoId: "c41891d5-c108-41ea-a13b-83750e0e377b",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "c3bf2b72-c52a-4bd4-9e95-bdae4f228fc1",
        playerTwoId: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 6, teamTwo: 1 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1a8d85d3-28d1-4abd-8fe2-fe8c2df55c33",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "0ba76f83-4020-4706-9162-9d23c89842d4",
        playerTwoId: "449e317d-11dc-4b5f-909b-04ceda005039",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
        playerTwoId: "8e1f4e39-1383-490c-b1b5-b3f99525aeb8",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 1 },
      setTwo: { teamOne: 6, teamTwo: 1 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ebce0449-8601-4b37-b91a-42429e5d4cc2",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        playerTwoId: "523077cc-506d-46b5-b435-f32dca04a838",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
        playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 0, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f0293388-e9a7-4a72-9c23-6242c8a80419",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "1cd72d27-6b4d-4399-a383-123b2fd95d75",
        playerTwoId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        points: 1,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0b06b26b-c804-4d61-bc8b-802ebbb71ed8",
        playerTwoId: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 2 },
      setThree: { teamOne: 2, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "49d72587-f19c-4a0e-a734-34eb5fa73868",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "f2e220d0-091c-4dd6-bbbf-764b2a8fb3df",
        playerTwoId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
        playerTwoId: "aff13e2d-edf8-4294-9172-05a3766838a3",
        points: 1,
      },
      setOne: { teamOne: 7, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 6, teamTwo: 2 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "625a229f-d260-4bdf-8138-5e4f4bee794a",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "0b7e4870-cdb9-4058-aa69-8c2b4a741f79",
        playerTwoId: "b70f2b9e-034c-42b3-b389-7e49a3294f50",
        points: 1,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
        playerTwoId: "8ffb6e94-32a8-4aac-9293-f05ddd521e13",
        points: 3,
      },
      setOne: { teamOne: 3, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 3 },
      setThree: { teamOne: 4, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "b871d473-ef04-440f-88e6-e4939fad7a7c",
      matchDate: moment("2024-01-17", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
        playerTwoId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        points: 0,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        playerTwoId: "792cd09b-f61d-4c6d-8e44-4562213bbc02",
        points: 3,
      },
      setOne: { teamOne: 4, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f3236153-b6fb-46af-b908-dbe8fdcafb9d",
      matchDate: moment("2024-01-22", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        playerTwoId: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
        points: 1,
      },
      setOne: { teamOne: 6, teamTwo: 2 },
      setTwo: { teamOne: 5, teamTwo: 7 },
      setThree: { teamOne: 6, teamTwo: 3 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1f92eb73-56fd-4d88-976e-d01d2d0dc6de",
      matchDate: moment("2024-01-22", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
        playerTwoId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        playerTwoId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        points: 1,
      },
      setOne: { teamOne: 6, teamTwo: 2 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 6, teamTwo: 3 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5078a729-2c3a-4108-a210-5f85026e8674",
      matchDate: moment("2024-01-22", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
        playerTwoId: "523077cc-506d-46b5-b435-f32dca04a838",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "fbeee4f3-ca39-4cf5-bf9d-10b53606c78c",
        playerTwoId: "953b3d82-a9ac-47c4-9256-2857d68297ad",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 6, teamTwo: 2 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "1aa601f7-672f-49b6-b6ca-a752cfdf924c",
      matchDate: moment("2024-01-22", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "b70f2b9e-034c-42b3-b389-7e49a3294f50",
        playerTwoId: "24e245e4-e9c9-4ce6-aacc-d642061a2854",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0b06b26b-c804-4d61-bc8b-802ebbb71ed8",
        playerTwoId: "6f590988-4ea3-4f2f-a741-08dd4e148662",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 0, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2063de22-ed61-46c5-99ae-276c2dd8d785",
      matchDate: moment("2024-01-22", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
        playerTwoId: "4259ac3a-46d6-4f35-adfb-0c2fcf684101",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0f034fc9-ff88-484d-953c-31473bd83c69",
        playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
        points: 1,
      },
      setOne: { teamOne: 7, teamTwo: 5 },
      setTwo: { teamOne: 3, teamTwo: 6 },
      setThree: { teamOne: 6, teamTwo: 3 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "ecade9e1-062c-4ba2-94cc-4d8c384a85be",
      matchDate: moment("2024-01-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "80f30866-fe10-4d6a-9f23-769f15d98e16",
        playerTwoId: "1cd72d27-6b4d-4399-a383-123b2fd95d75",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
        playerTwoId: "8ffb6e94-32a8-4aac-9293-f05ddd521e13",
        points: 1,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 3, teamTwo: 6 },
      setThree: { teamOne: 6, teamTwo: 3 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2680e673-6053-411c-89fc-9f57cc5e8e59",
      matchDate: moment("2024-01-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "449e317d-11dc-4b5f-909b-04ceda005039",
        playerTwoId: "0ba76f83-4020-4706-9162-9d23c89842d4",
        points: 0,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "3096d7a0-d877-429c-bb35-c0119e75b4b9",
        playerTwoId: "c3bf2b72-c52a-4bd4-9e95-bdae4f228fc1",
        points: 3,
      },
      setOne: { teamOne: 0, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2cf405fa-d6b5-4c9c-b9dc-f366a3dbb180",
      matchDate: moment("2024-01-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "f2e220d0-091c-4dd6-bbbf-764b2a8fb3df",
        playerTwoId: "58b67beb-e622-46d8-906f-609d3cb69cfe",
        points: 1,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "d9b904be-fb47-4271-8603-43df0a3e36f9",
        playerTwoId: "9ceeffc4-81db-457c-bd26-536599d51124",
        points: 3,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 4, teamTwo: 6 },
      setThree: { teamOne: 3, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "c063be46-bb53-4066-afac-6ab812e8194c",
      matchDate: moment("2024-01-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "5d39c67c-9736-42f5-a302-b10c415095c9",
        playerTwoId: "94acf0fe-9e87-4cdb-818e-b8a196153b4c",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
        playerTwoId: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 2, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "faadb18c-f0d4-45d2-8b1f-1c4b3dd33212",
      matchDate: moment("2024-01-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
        playerTwoId: "eda40a83-8fee-41cc-8165-741ba0919bc0",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
        playerTwoId: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 2, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2d8c9839-11a6-42f0-9a0b-4461fad50131",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
        points: 1,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 3 },
      setThree: { teamOne: 3, teamTwo: 4 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "c0d6fd67-ceab-4c46-a39d-2d99672db1eb",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
        playerTwoId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
        playerTwoId: "5d39c67c-9736-42f5-a302-b10c415095c9",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 1 },
      setTwo: { teamOne: 6, teamTwo: 1 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "aa0282a1-3778-44a6-9312-cfd65b926e57",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "b5a39031-e4f2-4390-844d-c5aab4d22575",
        playerTwoId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
        playerTwoId: "1138f590-58ea-4747-bffc-70f897c06e0e",
        points: 3,
      },
      setOne: { teamOne: 3, teamTwo: 6 },
      setTwo: { teamOne: 4, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "e40dd29b-2965-48a2-b7c1-6a7b82ea79a7",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "aff13e2d-edf8-4294-9172-05a3766838a3",
        playerTwoId: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
        playerTwoId: "6f590988-4ea3-4f2f-a741-08dd4e148662",
        points: 1,
      },
      setOne: { teamOne: 7, teamTwo: 5 },
      setTwo: { teamOne: 4, teamTwo: 6 },
      setThree: { teamOne: 6, teamTwo: 2 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "075f98da-e86b-4d05-9fd1-bcadf4141b7e",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
        playerTwoId: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
        playerTwoId: "af43c2ab-e945-4a05-8976-965a0be20690",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 1, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2f9eb39a-b7f4-4817-84ee-700d966d96dc",
      matchDate: moment("2024-01-29", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "953b3d82-a9ac-47c4-9256-2857d68297ad",
        playerTwoId: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
        playerTwoId: "6fafaa6d-27e0-4c05-ad54-c43e07f41903",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 2 },
      setTwo: { teamOne: 6, teamTwo: 3 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "5cef4fb4-cdcd-48c1-a4f7-195b33026a07",
      matchDate: moment("2024-01-31", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        playerTwoId: "80f30866-fe10-4d6a-9f23-769f15d98e16",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
        playerTwoId: "c41891d5-c108-41ea-a13b-83750e0e377b",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 4 },
      setTwo: { teamOne: 7, teamTwo: 5 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "a78369e3-2707-4008-9e56-6c8a24ef66ab",
      matchDate: moment("2024-01-31", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "449e317d-11dc-4b5f-909b-04ceda005039",
        playerTwoId: "8cf32cd8-635f-4929-9652-ef87521eced2",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "94acf0fe-9e87-4cdb-818e-b8a196153b4c",
        playerTwoId: "24e245e4-e9c9-4ce6-aacc-d642061a2854",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 1 },
      setTwo: { teamOne: 6, teamTwo: 1 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "a2e40e87-8f42-447c-adb2-1839f13217fe",
      matchDate: moment("2024-01-31", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        playerTwoId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "ff0dcf72-910e-422e-ac22-584d671b3980",
        playerTwoId: "0f034fc9-ff88-484d-953c-31473bd83c69",
        points: 3,
      },
      setOne: { teamOne: 5, teamTwo: 7 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "333eab2c-2c18-46b6-9a19-614a0aa2fb95",
      matchDate: moment("2024-01-31", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "792cd09b-f61d-4c6d-8e44-4562213bbc02",
        playerTwoId: "fee2925f-98f7-4ead-9580-ec61d266c370",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "50f96f0a-647b-43af-8b72-b5e52f23698c",
        playerTwoId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 6, teamTwo: 3 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "b427732f-af42-489d-be3a-89c041e5f9d0",
      matchDate: moment("2024-02-05", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "50f96f0a-647b-43af-8b72-b5e52f23698c",
        playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
        points: 1,
      },
      setOne: { teamOne: 4, teamTwo: 6 },
      setTwo: { teamOne: 7, teamTwo: 5 },
      setThree: { teamOne: 6, teamTwo: 4 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "4494d921-63a9-44eb-85c3-47e731f2735b",
      matchDate: moment("2024-02-05", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
        playerTwoId: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
        points: 1,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "4259ac3a-46d6-4f35-adfb-0c2fcf684101",
        playerTwoId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
        points: 3,
      },
      setOne: { teamOne: 1, teamTwo: 6 },
      setTwo: { teamOne: 4, teamTwo: 6 },
      setThree: { teamOne: 3, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "571a75e4-8043-48dc-9315-d098a591eb46",
      matchDate: moment("2024-02-05", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "792cd09b-f61d-4c6d-8e44-4562213bbc02",
        playerTwoId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        points: 1,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
        playerTwoId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        points: 3,
      },
      setOne: { teamOne: 7, teamTwo: 6 },
      setTwo: { teamOne: 6, teamTwo: 4 },
      setThree: { teamOne: 4, teamTwo: 6 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    // 2024-02-07
    {
      id: "a8169b72-6de0-4f03-8b3f-adc015e0f7b9",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "b5a39031-e4f2-4390-844d-c5aab4d22575",
        playerTwoId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
        playerTwoId: "ff0dcf72-910e-422e-ac22-584d671b3980",
        points: 1,
      },
      setOne: { teamOne: 6, teamTwo: 4 },
      setTwo: { teamOne: 6, teamTwo: 7 },
      setThree: { teamOne: 4, teamTwo: 1 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "f1701123-8b20-4a66-b820-5afd20cb5508",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        playerTwoId: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
        playerTwoId: "af43c2ab-e945-4a05-8976-965a0be20690",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 1 },
      setTwo: { teamOne: 7, teamTwo: 5 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "c5687a3c-39f5-4b4b-b2cd-bc144c219b96",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "523077cc-506d-46b5-b435-f32dca04a838",
        playerTwoId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
        playerTwoId: "0b06b26b-c804-4d61-bc8b-802ebbb71ed8",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 1 },
      setTwo: { teamOne: 6, teamTwo: 2 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "2b8d8e4b-3ff6-4003-ad9e-f3224c31e977",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "80f30866-fe10-4d6a-9f23-769f15d98e16",
        playerTwoId: "1cd72d27-6b4d-4399-a383-123b2fd95d75",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "8e1f4e39-1383-490c-b1b5-b3f99525aeb8",
        playerTwoId: "9ceeffc4-81db-457c-bd26-536599d51124",
        points: 3,
      },
      setOne: { teamOne: 2, teamTwo: 6 },
      setTwo: { teamOne: 1, teamTwo: 6 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "d548f69e-c76e-4189-9ddd-49ec09fc9b9c",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "8a988843-6dbd-4064-ac05-d0b628546b86",
        playerTwoId: "8a988843-6dbd-4064-ac05-d0b628546b86",
        points: 2,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "cd8faa9c-ad15-418e-9485-d02133dc8410",
        playerTwoId: "cd8faa9c-ad15-418e-9485-d02133dc8410",
        points: 2,
      },
      setOne: { teamOne: 0, teamTwo: 0 },
      setTwo: { teamOne: 0, teamTwo: 0 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "a3a9cac2-77aa-4039-9898-21d2fe6af9c8",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "fee2925f-98f7-4ead-9580-ec61d266c370",
        playerTwoId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "eda40a83-8fee-41cc-8165-741ba0919bc0",
        playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
        points: 0,
      },
      setOne: { teamOne: 6, teamTwo: 3 },
      setTwo: { teamOne: 6, teamTwo: 1 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    {
      id: "dac371a1-57e3-439e-a624-c8cfc6103cbd",
      matchDate: moment("2024-02-07", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "3096d7a0-d877-429c-bb35-c0119e75b4b9",
        playerTwoId: "aff13e2d-edf8-4294-9172-05a3766838a3",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
        playerTwoId: "94acf0fe-9e87-4cdb-818e-b8a196153b4c",
        points: 3,
      },
      setOne: { teamOne: 5, teamTwo: 7 },
      setTwo: { teamOne: 5, teamTwo: 7 },
      setThree: { teamOne: 0, teamTwo: 0 },
      createdById: "system",
      createdDate: moment(),
      modifiedById: "system",
      modifiedDate: moment(),
    },
    // 2024-02-12
    {
      id: "808d3764-1f4b-4f86-b217-8ab39873ea33",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "523077cc-506d-46b5-b435-f32dca04a838",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
        playerTwoId: "aff13e2d-edf8-4294-9172-05a3766838a3",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 2,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 3,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "4285fe45-9b8b-443d-97a1-a7fb0870d0a3",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
        points: 0,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "fee2925f-98f7-4ead-9580-ec61d266c370",
        playerTwoId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        points: 3,
      },
      setOne: {
        teamOne: 3,
        teamTwo: 6,
      },
      setTwo: {
        teamOne: 4,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "49684b0b-e1ea-4547-b955-5db42fbb3f77",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "b5a39031-e4f2-4390-844d-c5aab4d22575",
        playerTwoId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        playerTwoId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 1,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "8e2e6d65-e2d3-489d-bdeb-8a672b180cd4",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "0ba76f83-4020-4706-9162-9d23c89842d4",
        playerTwoId: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "953b3d82-a9ac-47c4-9256-2857d68297ad",
        playerTwoId: "fbeee4f3-ca39-4cf5-bf9d-10b53606c78c",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 2,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "b2e63118-ced3-4444-91c1-68d81291f9c0",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
        playerTwoId: "8e1f4e39-1383-490c-b1b5-b3f99525aeb8",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "0b7e4870-cdb9-4058-aa69-8c2b4a741f79",
        playerTwoId: "c41891d5-c108-41ea-a13b-83750e0e377b",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 1,
      },
      setTwo: {
        teamOne: 7,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "19f29c5b-7122-435c-9ec3-e9695cfb433d",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
        playerTwoId: "ff0dcf72-910e-422e-ac22-584d671b3980",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "1138f590-58ea-4747-bffc-70f897c06e0e",
        playerTwoId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 2,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 2,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "8f2e170e-707c-43b5-9a79-366dc480ac9e",
      matchDate: moment("2024-02-12", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "0b06b26b-c804-4d61-bc8b-802ebbb71ed8",
        playerTwoId: "6fafaa6d-27e0-4c05-ad54-c43e07f41903",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "58b67beb-e622-46d8-906f-609d3cb69cfe",
        playerTwoId: "24e245e4-e9c9-4ce6-aacc-d642061a2854",
        points: 3,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 7,
      },
      setTwo: {
        teamOne: 3,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    // 2024-02-14
    {
      id: "8b8833c9-2c9c-4205-b60d-6a5809df70e4",
      matchDate: moment("2024-02-14", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        playerTwoId: "80f30866-fe10-4d6a-9f23-769f15d98e16",
        points: 3,
      },
      teamTwo: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
        playerTwoId: "8ffb6e94-32a8-4aac-9293-f05ddd521e13",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 4,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "7cf0f478-08d3-4c18-9897-7cc5e8f1576d",
      matchDate: moment("2024-02-14", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "50f96f0a-647b-43af-8b72-b5e52f23698c",
        playerTwoId: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
        playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
        points: 3,
      },
      setOne: {
        teamOne: 5,
        teamTwo: 7,
      },
      setTwo: {
        teamOne: 3,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "aa8b8194-a634-4dc8-9e84-aabe436c430b",
      matchDate: moment("2024-02-14", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
        playerTwoId: "af43c2ab-e945-4a05-8976-965a0be20690",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "5d39c67c-9736-42f5-a302-b10c415095c9",
        playerTwoId: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 7,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    // 2024-02-19
    {
      id: "9d5f60a5-11e2-4698-b96a-848a300f1110",
      matchDate: moment("2024-02-19", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
        playerTwoId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "eda40a83-8fee-41cc-8165-741ba0919bc0",
        playerTwoId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
        points: 3,
      },
      setOne: {
        teamOne: 2,
        teamTwo: 6,
      },
      setTwo: {
        teamOne: 3,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "ea9b7be2-db04-44e8-98ff-04c9ee51dcce",
      matchDate: moment("2024-02-19", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
        playerTwoId: "a5cc942f-2449-4e71-8c61-3699bc59707a",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "4259ac3a-46d6-4f35-adfb-0c2fcf684101",
        playerTwoId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 3,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "e77b1fe0-372d-432f-96f5-adc5514300c1",
      matchDate: moment("2024-02-19", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "57b4c3c1-27d7-49f0-bd9b-a0f90da71bd2",
        playerTwoId: "56aa4026-9d1e-4367-8eee-d564d2777e99",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "f2e220d0-091c-4dd6-bbbf-764b2a8fb3df",
        playerTwoId: "b70f2b9e-034c-42b3-b389-7e49a3294f50",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 4,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "4dacc6fa-f63b-47e0-88bd-906497c4472d",
      matchDate: moment("2024-02-19", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
        playerTwoId: "fee2925f-98f7-4ead-9580-ec61d266c370",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "ff0dcf72-910e-422e-ac22-584d671b3980",
        playerTwoId: "0f034fc9-ff88-484d-953c-31473bd83c69",
        points: 3,
      },
      setOne: {
        teamOne: 1,
        teamTwo: 6,
      },
      setTwo: {
        teamOne: 2,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "88f21e5c-3c72-43d1-831f-7ed23d525cdb",
      matchDate: moment("2024-02-19", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
        playerTwoId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "1a2941cc-975f-4593-8cf9-58ea3a9c8c8d",
        playerTwoId: "50f96f0a-647b-43af-8b72-b5e52f23698c",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 3,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 3,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    // 2024-02-21
    {
      id: "afc8641a-9a35-4985-bc77-6d39936ee700",
      matchDate: moment("2024-02-21", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "0ba76f83-4020-4706-9162-9d23c89842d4",
        playerTwoId: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
        points: 0,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "94acf0fe-9e87-4cdb-818e-b8a196153b4c",
        playerTwoId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
        points: 3,
      },
      setOne: {
        teamOne: 3,
        teamTwo: 6,
      },
      setTwo: {
        teamOne: 5,
        teamTwo: 7,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "cf1748ac-6083-46fe-b553-e7e6b1aad41c",
      matchDate: moment("2024-02-21", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
        playerOneId: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
        playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
        points: 3,
      },
      teamTwo: {
        teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
        playerOneId: "5d39c67c-9736-42f5-a302-b10c415095c9",
        playerTwoId: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
        points: 0,
      },
      setOne: {
        teamOne: 7,
        teamTwo: 5,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 2,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "f81f87e9-a8eb-4cb1-bfcb-2b955dbe5da9",
      matchDate: moment("2024-02-21", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
        playerTwoId: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "e8bf76b9-8e8a-4093-9ffd-7209a28c8e21",
        playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
        points: 1,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 2,
      },
      setTwo: {
        teamOne: 3,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 6,
        teamTwo: 0,
      },
    },
    {
      id: "5acb4378-100e-4658-b9e0-1a6703a77e7b",
      matchDate: moment("2024-02-21", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "3096d7a0-d877-429c-bb35-c0119e75b4b9",
        playerTwoId: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
        points: 3,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "cc84e7dd-d536-4df9-9d8c-896ab5ae9594",
        playerTwoId: "9ceeffc4-81db-457c-bd26-536599d51124",
        points: 0,
      },
      setOne: {
        teamOne: 6,
        teamTwo: 2,
      },
      setTwo: {
        teamOne: 6,
        teamTwo: 1,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
    {
      id: "2cb64aed-436f-4158-927e-39b0d5324781",
      matchDate: moment("2024-02-21", "YYYY-MM-DD"),
      createdById: "system",
      createdDate: moment("2024-02-24", "YYYY-MM-DD"),
      modifiedById: "system",
      modifiedDate: moment("2024-02-24", "YYYY-MM-DD"),
      teamOne: {
        teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
        playerOneId: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
        playerTwoId: "2539d22b-ea44-489b-a9f4-1f8af9382e48",
        points: 0,
      },
      teamTwo: {
        teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
        playerOneId: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
        playerTwoId: "6f590988-4ea3-4f2f-a741-08dd4e148662",
        points: 3,
      },
      setOne: {
        teamOne: 2,
        teamTwo: 6,
      },
      setTwo: {
        teamOne: 2,
        teamTwo: 6,
      },
      setThree: {
        teamOne: 0,
        teamTwo: 0,
      },
    },
  ],
});

const febraury26thMatches: MatchBase[] = [
  {
    id: "e33de2bc-cf76-4486-983c-2578aff857cc",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      playerOneId: "73458893-3b28-4f43-b4d7-70aa606fd7ad",
      playerTwoId: "eda5b116-16f3-4ca8-b75e-020965605004",
      points: 3,
    },
    teamTwo: {
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      playerOneId: "af43c2ab-e945-4a05-8976-965a0be20690",
      playerTwoId: "7dcb30c8-1657-4ca0-981c-23b3f21146de",
      points: 0,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 2,
    },
    setTwo: {
      teamOne: 7,
      teamTwo: 5,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "a47e27c9-694a-4d46-84d4-cf30cd6cdc87",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      playerOneId: "a45d6fb0-a5b2-44f8-8415-e2f6fc3b24ff",
      playerTwoId: "5d843b32-69fd-43d6-877e-57bd6acccb46",
      points: 3,
    },
    teamTwo: {
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      playerOneId: "0f034fc9-ff88-484d-953c-31473bd83c69",
      playerTwoId: "70e14fe2-e6ff-491f-ac35-7eb11a16f338",
      points: 0,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 3,
    },
    setTwo: {
      teamOne: 7,
      teamTwo: 5,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "bea0f5e1-c0b3-40e4-b3b2-c4e89da6b14e",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      playerOneId: "523077cc-506d-46b5-b435-f32dca04a838",
      playerTwoId: "753cbc5d-ef78-42d1-9a37-32abc171e7c6",
      points: 3,
    },
    teamTwo: {
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      playerOneId: "6f590988-4ea3-4f2f-a741-08dd4e148662",
      playerTwoId: "5f60f8e4-a5c7-47cf-8530-3cbe124ee00e",
      points: 0,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 2,
    },
    setTwo: {
      teamOne: 7,
      teamTwo: 5,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "f6f820f9-572e-40ae-814e-e1e8a6225be7",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      playerOneId: "8d571fd1-7011-4c4e-80d5-b3113ca3f258",
      playerTwoId: "9a43c8c6-8f0f-48a4-a78b-1e509a045b53",
      points: 1,
    },
    teamTwo: {
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      playerOneId: "6f453fcc-68d6-4b07-951b-2c4ced67fdf8",
      playerTwoId: "771e2ee3-3aeb-4eb5-b3b4-d6ef928798c4",
      points: 3,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 2,
    },
    setTwo: {
      teamOne: 3,
      teamTwo: 6,
    },
    setThree: {
      teamOne: 3,
      teamTwo: 6,
    },
  },
  {
    id: "767a69eb-f338-4641-80e8-feb55526d490",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      playerOneId: "3f86cdf0-dfba-4349-9b38-25749fcf3fde",
      playerTwoId: "fee2925f-98f7-4ead-9580-ec61d266c370",
      points: 0,
    },
    teamTwo: {
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      playerOneId: "ea67d3a3-6974-4031-9359-6a2a3053e7bc",
      playerTwoId: "1138f590-58ea-4747-bffc-70f897c06e0e",
      points: 3,
    },
    setOne: {
      teamOne: 1,
      teamTwo: 6,
    },
    setTwo: {
      teamOne: 5,
      teamTwo: 7,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "db616d76-4e65-421f-9dcb-7da2d470458d",
    matchDate: moment("2024-02-26", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      playerOneId: "953b3d82-a9ac-47c4-9256-2857d68297ad",
      playerTwoId: "fbeee4f3-ca39-4cf5-bf9d-10b53606c78c",
      points: 3,
    },
    teamTwo: {
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      playerOneId: "0b7e4870-cdb9-4058-aa69-8c2b4a741f79",
      playerTwoId: "c41891d5-c108-41ea-a13b-83750e0e377b",
      points: 1,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 1,
    },
    setTwo: {
      teamOne: 3,
      teamTwo: 6,
    },
    setThree: {
      teamOne: 6,
      teamTwo: 4,
    },
  },
];

const febraury28thMatches: MatchBase[] = [
  {
    id: "a3917668-fe3f-4e93-83a2-7b39023c6844",
    matchDate: moment("2024-02-28", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      playerOneId: "b5a39031-e4f2-4390-844d-c5aab4d22575",
      playerTwoId: "f71f06fa-bc93-4bfb-93d4-de0e05803625",
      points: 0,
    },
    teamTwo: {
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      playerOneId: "e9303047-a99a-472c-8921-b28f2cc48b2e",
      playerTwoId: "ff0dcf72-910e-422e-ac22-584d671b3980",
      points: 3,
    },
    setOne: {
      teamOne: 0,
      teamTwo: 6,
    },
    setTwo: {
      teamOne: 5,
      teamTwo: 7,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "0d335c2c-5ee4-4786-9f16-3ea33bd3026b",
    matchDate: moment("2024-02-28", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "8b61907f-f281-4c39-9c65-00c8d2bdb11f",
      playerOneId: "0ba76f83-4020-4706-9162-9d23c89842d4",
      playerTwoId: "57b4c3c1-27d7-49f0-bd9b-a0f90da71bd2",
      points: 3,
    },
    teamTwo: {
      teamId: "8dcce536-6bfe-45e6-afa9-b593ac86a6c9",
      playerOneId: "6fafaa6d-27e0-4c05-ad54-c43e07f41903",
      playerTwoId: "9ceeffc4-81db-457c-bd26-536599d51124",
      points: 0,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 3,
    },
    setTwo: {
      teamOne: 7,
      teamTwo: 5,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
  {
    id: "3612703f-06e3-4c04-ac49-b2f47133d01d",
    matchDate: moment("2024-02-28", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      playerOneId: "7f1efd76-d68c-4ff5-a8ae-1ee5879b098d",
      playerTwoId: "82bed32b-53d7-4798-9f6f-8b45fc268a43",
      points: 3,
    },
    teamTwo: {
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      playerOneId: "9b61af62-dfff-48f6-8209-f9639f90bb8d",
      playerTwoId: "58b67beb-e622-46d8-906f-609d3cb69cfe",
      points: 1,
    },
    setOne: {
      teamOne: 3,
      teamTwo: 6,
    },
    setTwo: {
      teamOne: 6,
      teamTwo: 3,
    },
    setThree: {
      teamOne: 6,
      teamTwo: 1,
    },
  },
  {
    id: "e2974b86-97c4-4e42-9140-603309d7cdec",
    matchDate: moment("2024-02-28", "YYYY-MM-DD"),
    createdById: "system",
    createdDate: moment("2024-03-04", "YYYY-MM-DD"),
    modifiedById: "system",
    modifiedDate: moment("2024-03-04", "YYYY-MM-DD"),
    teamOne: {
      teamId: "67ff0b3d-c8b0-4c71-aecb-ecbcd1e7d52b",
      playerOneId: "135a8f4b-528a-4ab8-be47-17efe09aee28",
      playerTwoId: "893c7da5-a86c-4785-a7ee-81c30636ec9f",
      points: 3,
    },
    teamTwo: {
      teamId: "b03ae864-68b7-4fc6-bb18-0ebd447c5dba",
      playerOneId: "48135c04-f278-4bdb-a20e-66bf4f83af2c",
      playerTwoId: "ef8a26d9-0a30-4cbd-8df3-2e9940dd4aad",
      points: 0,
    },
    setOne: {
      teamOne: 6,
      teamTwo: 2,
    },
    setTwo: {
      teamOne: 6,
      teamTwo: 1,
    },
    setThree: {
      teamOne: 0,
      teamTwo: 0,
    },
  },
];

defaultPaddleClubBase.matches.push(...febraury26thMatches);
defaultPaddleClubBase.matches.push(...febraury28thMatches);

const get = (): PaddleClub => {
  return defaultPaddleClubBase;

  // const paddleClubLocalStorage = localStorage.getItem(paddleClubDataKey);

  // if (!paddleClubLocalStorage || stringUtils.isNullOrWhitespace(paddleClubLocalStorage)) {
  //   return defaultPaddleClubBase;
  // }

  // const serializer = new TypedJSON(PaddleClubBase);

  // try {
  //   const paddleClub = serializer.parse(paddleClubLocalStorage);

  //   if (paddleClub) {
  //     return paddleClub;
  //   }
  // } catch (e) {
  //   // nothing
  // }

  // return defaultPaddleClubBase;
};

const set = (paddleClub: PaddleClub): void => {
  let paddleClubToSerialize = PaddleClubBase.create(paddleClub);

  const serializer = new TypedJSON(PaddleClubBase);
  const data = serializer.stringify(paddleClubToSerialize);

  localStorage.setItem(paddleClubDataKey, data);
};

const localPaddleStorageUtil = {
  get,
  set,
};

export default localPaddleStorageUtil;
